/* noto-serif-sc-200 */
@font-face {
    font-family: 'Noto Serif SC';
    font-style: normal;
    font-weight: regular;
    src: url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.eot'); /* IE9 Compat Modes */
    src: local('Noto Serif SC'), local('NotoSerif SC-Normal'),
        url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.woff')
            format('woff'),
        /* Modern Browsers */
            url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('//lib.baomitu.com/fonts/noto-serif-sc/noto-serif-sc-200.svg#NotoSerif SC')
            format('svg'); /* Legacy iOS */
}
